<template>
  <div class="input-effect">
    <input
      v-if="type !== 'textarea'"
      class="effect-20"
      :id="id"
      :type="type"
      :name="post_name"
      @input="onInput"
      :autocomplete="autocomplete"
      :required="required"
      placeholder=""
    />
    <textarea
      v-else
      class="effect-20"
      :name="post_name"
      cols="40"
      rows="10"
      :required="required"
      placeholder=""
    ></textarea>
    <label>{{ placeholder }}</label>
    <span class="focus-border">
      <i></i>
    </span>
  </div>
</template>

<script>
export default {
  name: "text-input",
  props: {
    id: {
      type: String,
      default: "",
    },
    post_name: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    placeholder: {
      type: String,
      default: "",
    },
    autocomplete: {
      type: String,
      default: "off",
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      timer: null,
    };
  },
  methods: {
    onInput(event) {
      event.target.classList.toggle("has-content", event.target.value !== "");
    },
  },
};
</script>

<style scoped lang="less">
/* necessary to give position: relative to parent. */
input[type="text"],
input[type="date"],
input[type="email"],
textarea {
  font: ~"15px/24px" "Muli", sans-serif;
  color: #4e4d4d;
  box-sizing: border-box;
  letter-spacing: 1px;
  resize: none;
}

:focus {
  outline: none;
}

.input-effect {
  float: left;
  position: relative;
  margin: 15px 0;
} /* necessary to give position: relative to parent. */

input[type="text"],
input[type="date"],
input[type="email"],
textarea {
  font-size: ~"15px/24px";
  color: #333;
  width: 100%;
  box-sizing: border-box;
  letter-spacing: 1px;
}

input[type="date"]:not(.has-content) {
  color: transparent;
  &:focus {
    color: #333;
  }
}

.effect-20 {
  border: 1px solid #ccc;
  padding: 7px;
  transition: 0.4s;
  background: transparent;
}

.effect-20 ~ .focus-border:before,
.effect-20 ~ .focus-border:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #00a199;
  transition: 0.3s;
}
.effect-20 ~ .focus-border:after {
  top: auto;
  bottom: 0;
  left: auto;
  right: 0;
}
.effect-20 ~ .focus-border i:before,
.effect-20 ~ .focus-border i:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 0;
  background-color: #00a199;
  transition: 0.4s;
}
.effect-20 ~ .focus-border i:after {
  left: auto;
  right: 0;
  top: auto;
  bottom: 0;
}
.effect-20:focus ~ .focus-border:before,
.effect-20:focus ~ .focus-border:after,
.has-content.effect-20 ~ .focus-border:before,
.has-content.effect-20 ~ .focus-border:after {
  width: 100%;
  transition: 0.3s;
}
.effect-20:focus ~ .focus-border i:before,
.effect-20:focus ~ .focus-border i:after,
.has-content.effect-20 ~ .focus-border i:before,
.has-content.effect-20 ~ .focus-border i:after {
  height: 100%;
  transition: 0.4s;
}
.effect-20 ~ label {
  position: absolute;
  text-align: left;
  left: 14px;
  width: 100%;
  top: 10px;
  color: #aaa;
  transition: 0.3s;
  z-index: -1;
  letter-spacing: 0.5px;
}
.effect-20:focus ~ label,
.has-content.effect-20 ~ label {
  top: -18px;
  left: 0;
  font-size: 12px;
  color: #00a199;
  transition: 0.3s;
}
</style>
