<template>
  <div class="info-bar">
    <div class="socials">
      <a v-if="facebook" :href="facebook" target="_blank">
        <span class="mdi mdi-facebook"></span>
      </a>
      <a v-if="instagram" :href="instagram" target="_blank">
        <span class="mdi mdi-instagram"></span>
      </a>
      <a v-if="youtube" :href="youtube" target="_blank">
        <span class="mdi mdi-youtube"></span>
      </a>
      <a v-if="mail" class="mail-small" :href="'mailto:' + mail">
        <span class="mdi mdi-email"></span>
      </a>
    </div>
    <!--    <div class="ratings">-->
    <!--      <div class="stars" v-for="i in 5" v-bind:key="i.key">-->
    <!--        <span class="mdi mdi-star"></span>-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="contact">
      <div class="locations">
        <a href="geo:51.67060434358203, 5.616445976128254">
          <span class="mdi mdi-map-marker"></span>
          Ontmoetingscentrum Muzerijk - Klarinetstraat 4 - 5402BE Uden
        </a>
        <a href="geo:51.6587192, 5.7718031">
          <span class="mdi mdi-map-marker"></span>
          Gemeenschapshuis De Wilg - Wethouder Lindersstraat 11 - 5455GJ
          Wilbertoord
        </a>
      </div>
      <a class="location" href="/#location">
        <span class="mdi mdi-map-marker"></span>
      </a>
      <a :href="'tel:' + phone.replace('-', '')">
        <span class="mdi mdi-phone"></span>
        {{ phone }}
      </a>
      <a class="mail-full" :href="'mailto:' + mail" target="_blank">
        <span class="mdi mdi-email"></span>
        {{ mail }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "InfoBar",
  props: {
    facebook: {
      default: "",
      type: String,
    },
    instagram: {
      default: "",
      type: String,
    },
    youtube: {
      default: "",
      type: String,
    },
    whatsapp: {
      default: "",
      type: String,
    },
    mail: {
      default: "",
      type: String,
    },
    phone: {
      default: "",
      type: String,
    },
  },
};
</script>

<style scoped lang="less">
.info-bar {
  position: relative;
  display: flex;
  max-width: 100%;
  font-size: 16px;
  justify-content: space-around;
  color: #ffffff;
  border-bottom: 1px solid gray;
  z-index: 99;
  height: 50px;
  a {
    color: #ffffff;
    padding: 0 5px;
    &:hover {
      color: var(--primary-color);
    }
  }
  .socials {
    display: flex;
    font-size: 20px;
    align-items: center;
  }
  .ratings {
    display: flex;
    align-items: center;
  }
  .contact {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .mail-small {
    display: none;
  }
  .locations {
    display: flex;
    flex-direction: row;
  }
  .location {
    display: none;
  }
}

/* Tablet */
@media screen and (max-width: 1280px) {
  .info-bar {
    .mail-full {
      display: none;
    }
    .mail-small {
      display: unset;
    }
  }
}

/* Tablet and Mobile */
@media screen and (max-width: 1024px) {
  .info-bar {
    .locations {
      display: none;
    }
    .location {
      display: unset;
    }
  }
}
</style>
