<template>
  <top />
  <div id="page">
    <router-view />
  </div>
  <bottom />
</template>

<script>
import top from "@/components/Top";
import bottom from "@/components/Bottom";

export default {
  name: "App",
  head: {
    meta: [{ name: "charset", content: "utf-8" }],
  },
  components: {
    top,
    bottom,
  },
};
</script>

<style lang="less">
@import url("@mdi/font/css/materialdesignicons.css");
@import url("./assets/style/default.less");
</style>
