<template>
  <div class="treatments">
    <div class="treatment_list">
      <tooltip
        v-for="treatment in treatments"
        :key="treatment.key"
        :text="treatment.title"
      >
        <a :href="'/behandelvormen/' + treatment.url" class="treatment">
          <i :class="['mdi', 'mdi-' + treatment.icon]"></i>
          <!--          <p class="treatment_title">{{ treatment.title }}</p>-->
        </a>
      </tooltip>
    </div>
  </div>
</template>

<script>
import Tooltip from "@/components/tooltip/Tooltip.vue";

export default {
  name: "Treatments",
  components: { Tooltip },
  data() {
    return {
      treatments: [
        { title: "Fysiotherapie", icon: "run", url: "fysiotherapie" },
        {
          title: "Manuele Therapie",
          icon: "hand-back-right",
          url: "manuele-therapie",
        },
        {
          title: "Neurorevalidatie",
          icon: "brain",
          url: "neurorevalidatie",
        },
        {
          title: "Revalidatietraining",
          icon: "soccer",
          url: "revalidatie-training",
        },
        {
          title: "Valpreventie",
          icon: "walk",
          url: "valpreventie",
        },
        { title: "Massage", icon: "hand-clap", url: "massage" },
        { title: "Dry Needling", icon: "needle", url: "dry-needling" },
        {
          title: "Elektrische Spierstimulatie",
          icon: "lightning-bolt",
          url: "elektrische-spierstimulatie",
        },
        { title: "Medical Taping", icon: "bandage", url: "medical-taping" },
        {
          title: "Virtual Reality Training",
          icon: "controller",
          url: "virtual-reality-training",
        },
        { title: "E-consult", icon: "forum", url: "e-consult" },
      ],
    };
  },
};
</script>

<style scoped lang="less">
.treatments {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 20px 0;
  .treatment_list {
    display: flex;
    justify-items: center;
    width: 100%;
    justify-content: space-around;
    flex-wrap: wrap;
    .treatment {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #ffffff;
      &:hover {
        color: var(--primary-color);
      }
      i {
        font-size: 50px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .treatments {
    .treatment_list {
      .treatment {
        .treatment_title {
          display: none;
        }
      }
    }
  }
}
</style>
