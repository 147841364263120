<template>
  <div @click="close" :class="{ overlay: true, open: menuOpen }"></div>
  <div :class="{ menu: true, open: menuOpen }">
    <div class="menu-header">
      <a href="/">
        <img
          src="/assets/img/logo/FAAM-Fysiotherapie-Logo.png"
          alt="Logo"
          class="logo logo-big"
        />
        <img
          src="/assets/img/logo/FF-262x300.png"
          alt="Logo"
          class="logo logo-small"
        />
      </a>
      <span class="mdi mdi-close close" @click="close" />
    </div>
    <nav>
      <div class="menu-item" v-for="item in items" :key="item.key">
        <div class="submenu-item" v-if="item.submenu">
          <router-link
            v-if="item.url"
            :to="item.url ? item.url : '#'"
            :class="item.classes"
          >
            {{ item.title }}
          </router-link>
          <a href="#" v-else>{{ item.title }}</a>
          <div class="submenu">
            <router-link
              v-for="subitem in item.submenu"
              class="sub-item"
              :to="item.url + subitem.url"
              :key="subitem.key"
            >
              {{ subitem.title }}
            </router-link>
          </div>
        </div>
        <div v-else-if="item.url.startsWith('#')">
          <a :href="item.url">
            {{ item.title }}
          </a>
        </div>
        <router-link v-else :to="item.url" :class="item.classes">
          {{ item.title }}
        </router-link>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "Menu",
  data: function () {
    return {
      items: [
        { title: "Welkom", url: "/" },
        {
          title: "Wij zijn Faam",
          url: "/faam",
          submenu: [
            { title: "Praktijk", url: "/praktijk" },
            { title: "Ons team", url: "/team" },
            { title: "Samenwerkingen", url: "/samenwerkingen" },
          ],
        },
        { title: "Patientverhalen", url: "/patient-verhalen" },
        {
          title: "Behandelvormen",
          url: "/behandelvormen",
          submenu: [
            {
              title: "Fysiotherapie",
              url: "/fysiotherapie",
              classes: "",
            },
            {
              title: "Manuele Therapie",
              url: "/manuele-therapie",
              classes: "",
            },
            {
              title: "Neurorevalidatie",
              url: "/neurorevalidatie",
              classes: "",
            },
            {
              title: "Revalidatietraining",
              url: "/revalidatie-training",
              classes: "",
            },
            { title: "Valpreventie", url: "/valpreventie", classes: "" },
            { title: "Massage", url: "/massage", classes: "" },
            {
              title: "Dry Needling",
              url: "/dry-needling",
              classes: "",
            },
            {
              title: "Elektrische Spierstimulatie",
              url: "/elektrische-spierstimulatie",
              classes: "",
            },
            {
              title: "Medical Taping",
              url: "/medical-taping",
              classes: "",
            },
            {
              title: "Virtual Reality Training",
              url: "/virtual-reality-training",
              classes: "",
            },
            { title: "Fitness", url: "/fitness", classes: "" },
            { title: "E-consult", url: "/e-consult", classes: "" },
          ],
        },
        {
          title: "Pijnklachten",
          url: "/pijnklachten",
          submenu: [
            { title: "Nek", url: "/nek", classes: "" },
            {
              title: "Schouder",
              url: "/schouder",
              classes: "",
            },
            { title: "Elleboog", url: "/elleboog", classes: "" },
            { title: "Pols", url: "/pols", classes: "" },
            { title: "Rug", url: "/rug", classes: "" },
            { title: "Heup", url: "/heup", classes: "" },
            { title: "Knie", url: "/knie", classes: "" },
            { title: "Enkel", url: "/enkel", classes: "" },
            { title: "Neurologie", url: "/neurologie", classes: "" },
            {
              title: "Overig",
              url: "/overig",
              classes: "",
            },
          ],
        },
        { title: "Tarievenlijst", url: "/tarieven", classes: "" },
        { title: "Contact", url: "/contact", classes: "" },
        {
          title: "Gratis inloopspreekuur",
          url: "/inloop-spreekuur",
          classes: "highlight",
        },
      ],
    };
  },
  methods: {
    close() {
      this.$store.commit("menu/close");
    },
  },
  watch: {
    $route() {
      this.$store.commit("menu/close");
    },
  },
  computed: {
    menuOpen() {
      return this.$store.getters["menu/isOpen"];
    },
  },
};
</script>

<style scoped lang="less">
.menu {
  display: flex;
  max-width: 100%;
  max-height: 100%;
  top: 0;
  z-index: 1;
  flex-direction: column;
  .menu-header {
    display: none;
    align-items: center;
    justify-content: space-around;
    .logo {
      padding: 20px 0;
      max-height: 50px;
      max-width: 100%;
      &.logo-big {
        height: 100%;
      }
      &.logo-small {
        display: none;
      }
      img {
        max-height: 100%;
        max-width: 100%;
      }
    }
    .close {
      padding-right: 5px;
      font-size: 30px;
      color: #ffffff;
      cursor: pointer;
      &:hover {
        color: #4e4d4d;
      }
    }
  }
  nav {
    display: flex;
    flex: 1;
    text-align: right;
    align-items: center;
    padding-right: 20px;
    .menu-item {
      a {
        font-weight: bold;
        color: #ddd;
        text-decoration: none;
        padding: 5px;

        &.router-link-exact-active,
        &:hover {
          color: #00a199;
        }

        &.highlight {
          color: #00a199;

          &:hover {
            color: #ffffff;
          }
        }
      }
    }
    .submenu-item {
      position: relative;
      display: inline-block;
      .submenu {
        display: none;
        position: absolute;
        flex-direction: column;
        text-align: left;
        background-color: rgba(0, 0, 0, 0.5);
        min-width: 100%;
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.8);
        padding: 12px 10px;
        border-radius: 5%;
        z-index: 1;
        opacity: 0;
        transition-duration: 0.7s;
      }
      &:hover {
        .submenu {
          display: flex;
          opacity: 1;
        }
      }
      &:has(> .submenu > .router-link-exact-active) {
        a:not(.sub-item) {
          color: #00a199;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .menu-icon:focus > .overlay {
    display: unset;
    opacity: 0.7;
    width: 20vw;
  }
  .menu-icon:focus > .menu {
    display: unset;
    left: 20vw;
  }
  .overlay {
    position: fixed;
    height: 100vh;
    width: 0;
    left: 0;
    top: 0;
    background-color: #4e4d4d;
    opacity: 0;
    transition: 0.5s all ease;
    &.open {
      display: unset;
      opacity: 0.7;
      width: 20vw;
    }
  }
  .menu {
    position: fixed;
    align-content: flex-end;
    left: 100vw;
    width: 80vw;
    height: 100vh;
    transition: 0.5s left ease;
    background-color: #00a199;
    overflow-y: scroll;
    &.open {
      left: 20vw;
    }
    .menu-header {
      display: flex;
      justify-content: space-between;
      padding: 0 10px;
      .logo {
        max-height: 100px;
        &.logo-big {
          display: none;
        }
        &.logo-small {
          display: unset;
          padding-left: 10px;
          filter: drop-shadow(2px 4px 6px #000);
        }
      }
    }
    nav {
      flex-direction: column;
      align-items: flex-end;
      text-align: right;
      height: 100%;
      padding: 0;
      .menu-item {
        margin: 7px 0;
        font-size: 40px;
        max-width: 100%;
        padding: 0 10px;

        a {
          font-weight: lighter;
          font-family: MasterPlan, MADE TOMMY, Montserrat, Open Sans, Sans-Serif,
            serif;
          &.router-link-exact-active,
          &:hover {
            color: #4e4d4d !important;
          }
          &.highlight {
            color: #ddd;
            filter: drop-shadow(2px 4px 6px #000);

            &:hover,
            &:focus,
            &:active {
              color: #4e4d4d;
            }
          }
        }
      }
      .submenu-item {
        display: flex;
        flex-direction: column;
        .submenu {
          display: flex;
          flex-direction: column;
          position: unset;
          padding: 0;
          opacity: 1;
          box-shadow: none;
          font-size: 20px;
          text-align: right;
          background-color: rgba(0, 0, 0, 0);
          a {
            color: #4e4d4d;
          }
        }
        &:has(> .submenu > .router-link-exact-active) {
          a:not(.sub-item) {
            color: #4e4d4d;
          }
        }
      }
    }
  }
}
</style>
