export default {
  namespaced: true,
  state: {
    deviceType: "desktop",
  },
  getters: {
    isPhone: function (state) {
      return state.deviceType === "phone";
    },
    isTablet: function (state) {
      return state.deviceType === "tablet";
    },
    isDesktop: function (state) {
      return state.deviceType === "desktop";
    },
    getDeviceType: function (state) {
      return state.deviceType;
    },
  },
  mutations: {
    evaluateDeviceType(state) {
      const screenWidth = window.innerWidth;
      switch (true) {
        case screenWidth > 0 && screenWidth <= 500:
          state.deviceType = "phone";
          break;
        case screenWidth > 500 && screenWidth <= 1050:
          state.deviceType = "tablet";
          break;
        case screenWidth > 1050:
        default:
          state.deviceType = "desktop";
          break;
      }
    },
  },
  actions: {
    init(context) {
      context.commit("evaluateDeviceType");
      window.addEventListener("resize", () => {
        setTimeout(() => context.commit("evaluateDeviceType"), 100);
      });
    },
  },
};
