<template>
  <a :href="item.url" :class="{ slide: true, fade: true, active: active }">
    <img :src="item.image" style="width: 100%" alt="item.title" />
    <div class="description">
      <h1>{{ item.title }}</h1>
      <p>{{ item.description }}</p>
      <p>{{ item.full_description }}</p>
    </div>
  </a>
</template>
<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
<style>
.slide {
  display: none;
  transition-duration: 0.3s;

  .description {
    color: #f2f2f2;
    font-size: 15px;
    padding: 8px 12px;
    position: absolute;
    bottom: 4px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  &.fade {
    animation-name: fade;
    animation-duration: 1.5s;
  }

  &.active {
    display: block;
  }

  &:hover > img {
    opacity: 0.8;
  }
}

@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

@media only screen and (max-width: 300px) {
  .description {
    font-size: 11px;
  }
}
</style>
