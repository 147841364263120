export default {
  namespaced: true,
  state: {
    open: false,
  },
  getters: {
    isOpen: function (state) {
      return state.open;
    },
  },
  mutations: {
    open(state) {
      state.open = true;
      // document.body.style.position = "fixed";
      // document.body.style.top = `-${window.scrollY}px`;
    },
    close(state) {
      state.open = false;
      // const scrollY = document.body.style.top;
      // document.body.style.position = "";
      // document.body.style.top = "";
      // window.scrollTo(0, parseInt(scrollY || "0") * -1);
    },
    toggle(state) {
      state.open = !state.open;
      // if (state.open) {
      //   document.body.style.position = "fixed";
      //   document.body.style.top = `-${window.scrollY}px`;
      // } else {
      //   const scrollY = document.body.style.top;
      //   document.body.style.position = "";
      //   document.body.style.top = "";
      //   window.scrollTo(0, parseInt(scrollY || "0") * -1);
      // }
    },
  },
};
