import { createApp } from "vue";
import { VueHeadMixin, createHead } from "@unhead/vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

// store.dispatch("device/init").finally();

const app = createApp(App);

createHead();
app.mixin(VueHeadMixin);
app.use(store);
app.use(router);
app.mount("#app");
