<template>
  <div class="tooltip">
    <slot></slot>
    <span class="tooltiptext">{{ text }}</span>
  </div>
</template>

<script>
export default {
  name: "Tooltip",
  props: {
    text: String,
  },
};
</script>

<style scoped lang="less">
.tooltip {
  position: relative;
  display: inline-block;

  .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
  }

  &:hover .tooltiptext {
    visibility: visible;
  }
}
</style>
